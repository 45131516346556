import axios from "axios";

const BASE_FULL_URL = process.env.VUE_APP_BASE_API || "/api";

export default () => {
  const defaultOptions = {
    baseURL: BASE_FULL_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  };

  // Create instance
  let instance = axios.create(defaultOptions);
  instance.interceptors.request.use(config => {
    const token = sessionStorage.getItem("Access-Token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  })

  return instance;
};
