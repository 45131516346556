const state = {
  loading: {
    show: false,
  }
};

const getters = {
  getLoadingDialog: (state) => {
    return state.loading;
  },
};

const actions = {
  showLoadingDialog({ commit }) {
    commit("SHOW_LOADING", true);
  },
  dismissLoadingDialog({ commit }) {
    commit("DISMISS_LOADING", false);
  },
};

const mutations = {
  SHOW_LOADING(state, loading) {
    state.loading.show = loading;
  },
  DISMISS_LOADING(state, loading) {
    state.loading.show = loading;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
