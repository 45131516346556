<template>
  <div>
    <v-navigation-drawer app v-model="drawer" v-if="drawer" permanent>
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="accent--text text--accent-4">
          <v-list-item to="/history">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>หน้าหลัก</v-list-item-title>
          </v-list-item>

          <v-list-item to="/expensesHistory">
            <v-list-item-icon>
              <v-icon>mdi-swap-horizontal</v-icon>
            </v-list-item-icon>
            <v-list-item-title>รายจ่าย</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="primary">
      <v-app-bar-nav-icon class="white--text" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="white--text">Finance Invoice System</v-toolbar-title>

      <v-spacer></v-spacer>
      <label class="white--text"> {{ fullname }} </label>
      <v-icon class="ma-2 white--text" @click="logout()">mdi-logout</v-icon>
    </v-app-bar>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router";

export default {
  name: "NavBar",
  props: {
    fullname: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    drawer: true,
  }),
  methods: {
    logout() {
      axios
        .get("/api/logout")
        .then((res) => {
          console.log(res);
          sessionStorage.clear();
          location.reload();
          router.push({ name: "LoginView" });
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
};
</script>
