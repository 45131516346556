<template>
  <v-app>
    <NavBar v-if="fullname.length != 0" :fullname="fullname" />
    <LoadingDialog v-model="loading.show" />

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar";
import LoadingDialog from "@/components/dialog/LoadingDialog";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    NavBar,
    LoadingDialog,
  },
  beforeUpdate() {
    this.getFullname();
  },
  data: () => ({
    fullname: "",
  }),
  computed: {
    ...mapGetters({
      loading: "dialog/getLoadingDialog",
    }),
  },
  methods: {
    ...mapActions({
      showLoading: "dialog/showLoadingDialog",
      dismissLoading: "dialog/dismissLoadingDialog",
    }),
    getFullname() {
      this.fullname = sessionStorage.getItem("user");
      if (this.fullname != null) {
        let myobj = JSON.parse(this.fullname);
        this.fullname = myobj.fullname;
      }
    },
  },
};
</script>
