import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '@/views/LoginView'
// import FinanaceHistory from '@/views/FinanaceHistory'

Vue.use(VueRouter)
const DEFAULT_TITLE = process.env.VUE_APP_TITLE || "Finance Invoice System";

const routes = [
  {
    path: '/expensesHistory',
    name: 'expensesHistory',
    component: () => import(/* webpackChunkName: "about" */ '../views/ExpensesHistory.vue'),
    meta: { title: "หน้ารายจ่าย", requiredLogin: true }
  },
  {
    path: '/',
    name: 'LoginView',
    component: LoginView,
    meta: { requiredLogin: false, title: "เข้าสู่ระบบ" }
  }
  , {
    path: '/history',
    name: 'FinanaceHistory',
    component: () => import(/* webpackChunkName: "about" */ '../views/FinanaceHistory.vue'),
    meta: { title: "หน้าหลัก", requiredLogin: true }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// ทุกครั้งก่อนที่จะเปลี่ยนหน้า
router.beforeEach((to, from, next) => {
  // ดึงข้อมูลผู้ใช้งานจาก session storage
  let user = JSON.parse(sessionStorage.getItem("user"))

  // เช็คว่าหน้าที่จะไปต้องทำการเข้าสู่ระบบก่อนหรือไม่
  if (to.meta.requiredLogin) {
    // ถ้าต้องเข้าสู่ระบบก่อน ก็เช็คว่า user is null ถ้า null ให้พาไปหน้าล็อคอิน ถ้าไม่ null ก็ผ่านเลยไป
    if (user === undefined || user === null) {
      next({
        path: "/",
        params: { nextUrl: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    // ถ้าไม่ต้องเข้าสู่ระบบก่อน ก็เช็คว่า user is null ถ้า null ก็ผ่านเลยไป แต่ถ้าไม่ null ก็พาไปหน้าประวัติ
    if (user !== null) {
      next({
        path: "/history",
        params: { nextUrl: to.fullPath },
      });
    } else {
      next();
    }
  }
})

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title != undefined ? to.meta.title + " - " + DEFAULT_TITLE : DEFAULT_TITLE;
  });
});


export default router
