<template>
  <v-main>
    <v-container fill-height>
      <v-card class="mx-auto d-flex flex-column col-sm-6">
        <v-card-title class="display-1 align-self-center">Finance Invoice System</v-card-title>

        <v-card-text>
          <v-form @submit.prevent="login()">
            <v-text-field v-model="username" label="ชื่อผู้ใช้งาน" required prepend-inner-icon="mdi-account-circle" outlined dense />
            <v-text-field v-model="password" label="รหัสผ่าน" required :type="showPassword ? 'text' : 'password'" prepend-inner-icon="mdi-lock" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword" outlined dense />
            <v-btn class="col-sm-12" type="submit" color="primary" :loading="btnLoginLoading">เข้าสู่ระบบ</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import router from "../router";
import APIClient from "@/core/base/BaseAPIService";

export default {
  name: "LoginView",
  created() {
    sessionStorage.removeItem("Access-Token");
  },
  data() {
    return {
      username: "",
      password: "",
      showPassword: false,
      btnLoginLoading: false,
    };
  },

  methods: {
    async login() {
      const payload = {
        username: this.username,
        password: this.password,
      };

      this.btnLoginLoading = true;

      await APIClient()
        .post("/login", payload)
        .then((res) => {
          this.btnLoginLoading = false;
          let user = {
            userId: res.data.userId,
            username: res.data.username,
            fullname: res.data.fullname,
          };

          sessionStorage.setItem("user", JSON.stringify(user));
          sessionStorage.setItem("Access-Token", res.data.token);

          router.push({ name: "FinanaceHistory" });
        })
        .catch((err) => {
          this.btnLoginLoading = false;
          console.log(err.response);
          alert("Username or Password Wrong!");
        });
    },
  },
};
</script>

<style scoped>
.v-main {
  height: 100%;
  background: radial-gradient(circle, var(--v-secondary-base) 0%, var(--v-primary-base) 100%) !important;
}
</style>
